import { isPlatformServer } from "@angular/common";
import { PLATFORM_ID, inject } from "@angular/core";
import { CanActivateChildFn, Router } from "@angular/router";
import { TranslocoService } from "@jsverse/transloco";
import { TranslateService } from "../services/translate.service";

export const languageGard: CanActivateChildFn = (childRoute, state) => {
    const ts = inject(TranslateService);
    const router = inject(Router);
    const transloco = inject(TranslocoService);
    const platformId = inject(PLATFORM_ID);

    const routeLang = childRoute.data['lang'];

    if (isPlatformServer(platformId)) {
        if (routeLang) {
            transloco.setActiveLang(routeLang);
            transloco.setDefaultLang(routeLang);
        }
        return true;
    }

    const urlLang = state.url.split('/')[1];
    if (routeLang && routeLang !== urlLang) {
        const url = '/' + urlLang + ts.translate(state.url.slice(3), urlLang);
        return router.createUrlTree([ url ]);
    }

    return true;
};
